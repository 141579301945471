import { ref } from "vue"
import { defineStore } from "pinia"
import {
    ChatMessage,
    ChatPerTicket,
    Ticket,
    TicketAssignee,
    TicketDetails,
    TicketDetailsWithoutMessages,
    TicketDetailsEditionPayload,
    TicketingStats,
    TicketListResponse,
    TicketTablePageParams,
    TicketBatchAssignationPayload,
    TicketFilterParams,
    ChatFile,
    TicketGroup,
    TicketingRouteConf,
    TicketBatchPayload
} from "@/models/crm/ticketing"
import Api from "@/lib/Api"
import { ticketingEventHub } from "@/lib/eventHub"
import { watchDebounced } from "@vueuse/core"
import { TICKETING_CONF_PER_GROUP } from "@/constants/crm/ticketing"

export const useTicketingStore = defineStore("ticketing", () => {
    const tickets = ref<Ticket[]>([])
    const ticketTotalCount = ref<number>(0)

    const ticketingStats = ref<TicketingStats>()

    const getTicketingStats = async () => {
        ticketingStats.value = await Api.get("/admn/ticketing_stats/")
        return ticketingStats.value
    }

    const isTicketListLoading = ref(false)
    const ticketTablePageParams = ref<TicketTablePageParams>({
        page: 1,
        itemsPerPage: 10
    })

    const ticketTableFilters = ref<TicketFilterParams>({
        searchType: "ticket_id_or_user",
        keyword: "",
        states: [],
        assigneeIds: []
    })
    const getTickets = async (ticketGroup: TicketGroup): Promise<Ticket[]> => {
        isTicketListLoading.value = true
        const params = getTicketTableSearchParams(ticketGroup)
        try {
            const res: TicketListResponse = await Api.get(`/admn/ticketing/?${params}`)
            tickets.value = res.results
            ticketTotalCount.value = res.total_count
            return res.results
        } finally {
            isTicketListLoading.value = false
        }
    }
    const getTicketTableSearchParams = (ticketGroup: TicketGroup): string => {
        const paramsDict: Record<string, string> = {
            group: ticketGroup.replaceAll("-", "_"),
            page_size: ticketTablePageParams.value.itemsPerPage.toString(),
            page: ticketTablePageParams.value.page.toString()
        }
        if (ticketTableFilters.value.keyword) {
            paramsDict.search_type = ticketTableFilters.value.searchType
            paramsDict.kw = ticketTableFilters.value.keyword
        }
        if (ticketTableFilters.value.states.length > 0) {
            paramsDict.states = ticketTableFilters.value.states.join(",")
        }
        if (ticketTableFilters.value.assigneeIds.length > 0) {
            paramsDict.assignee_ids = ticketTableFilters.value.assigneeIds.join(",")
        }
        return decodeURIComponent(new URLSearchParams(paramsDict).toString())
    }

    const ticketIdsToLoad = ref<number[]>([])
    const ticketsDetails = ref<TicketDetails[]>([])
    const getTicketsDetails = async (ticketsToFetch: number[]): Promise<TicketDetails[]> => {
        ticketIdsToLoad.value = [...ticketsToFetch]

        const promises: Promise<TicketDetails>[] = ticketsToFetch.map(async (ticketId: number) => {
            const [ticketDetailsWithoutMessages, chatPerTicketList] = await Promise.all([
                getTicketDetailsWithoutMessages(ticketId),
                getTicketMessages(ticketId)
            ])

            const ticketDetails: TicketDetails = {
                ...ticketDetailsWithoutMessages,
                chat_per_ticket: chatPerTicketList
            }

            const alreadyOpenTicketIndex = ticketsDetails.value.findIndex(
                ticketDetails => ticketDetails.id === ticketId
            )

            if (alreadyOpenTicketIndex > -1) {
                ticketsDetails.value.splice(alreadyOpenTicketIndex, 1, ticketDetails)
            } else {
                ticketsDetails.value.push(ticketDetails)
            }

            const ticketLoadedIndex = ticketIdsToLoad.value.findIndex((id: number) => id === ticketDetails.id)
            if (ticketLoadedIndex > -1) {
                ticketIdsToLoad.value.splice(ticketLoadedIndex, 1)
            }

            return ticketDetails
        })

        const ticketDetails = await Promise.race(promises)
        ticketingEventHub.emit("ticket-details-ready", ticketDetails.id)

        return Promise.all(promises)
    }
    const getTicketDetailsWithoutMessages = (ticketId: number): Promise<TicketDetailsWithoutMessages> => {
        return Api.get(`/admn/ticketing/${ticketId}/`)
    }
    const getTicketMessages = async (ticketId: number): Promise<ChatPerTicket[]> => {
        const chatPerTicket: ChatPerTicket[] = await Api.get(`/admn/ticketing/${ticketId}/messages/`)
        chatPerTicket.forEach(chat => {
            groupFileMessages(chat.messages)
        })
        return chatPerTicket
    }
    const groupFileMessages = (chatMessages: ChatMessage[]): ChatMessage[] => {
        const processedFileGroups = new Set<number>()
        let fileGroupToProcess: number | undefined | null

        do {
            fileGroupToProcess = chatMessages.find(
                chatMessage => chatMessage.file_group && !processedFileGroups.has(chatMessage.file_group)
            )?.file_group
            if (fileGroupToProcess) {
                const chatMessagesSameGroup = chatMessages.filter(message => message.file_group === fileGroupToProcess)
                if (chatMessagesSameGroup.length > 1) {
                    const groupedFiles: ChatFile[] = chatMessagesSameGroup.reduce(
                        (groupedFiles: ChatFile[], message: ChatMessage) => {
                            if (message.files) {
                                groupedFiles.push(message.files[0])
                            }
                            return groupedFiles
                        },
                        []
                    )

                    const firstChatOfItsGroupIndex = chatMessages.findIndex(
                        message => message.file_group === fileGroupToProcess
                    )
                    chatMessages[firstChatOfItsGroupIndex].files = groupedFiles
                    chatMessages.splice(firstChatOfItsGroupIndex + 1, chatMessagesSameGroup.length - 1)
                }

                processedFileGroups.add(fileGroupToProcess)
            }
        } while (!!fileGroupToProcess)

        return chatMessages
    }

    const detailedTicketToDisplay = ref<TicketDetails>()

    const updateTicketDetails = async (
        ticketId: number,
        payload: TicketDetailsEditionPayload
    ): Promise<TicketDetails> => {
        const ticketDetailsBackup = ticketsDetails.value.find(t => t.id === ticketId)
        const updatedTicketDetails: TicketDetailsWithoutMessages = await Api.patch(
            `/admn/ticketing/${ticketId}/`,
            payload
        )
        const newTicketDetails: TicketDetails = {
            ...updatedTicketDetails,
            chat_per_ticket: ticketDetailsBackup?.chat_per_ticket || []
        }
        const ticketDetailsIndex = ticketsDetails.value.findIndex(t => t.id === updatedTicketDetails.id)
        if (ticketDetailsIndex > -1) {
            ticketsDetails.value.splice(ticketDetailsIndex, 1, newTicketDetails)
        }
        return newTicketDetails
    }
    const sendMessage = async (ticketId: number, payload: { message: string }): Promise<ChatMessage> => {
        return await Api.post(`/admn/ticketing/${ticketId}/messages/`, payload)
    }
    const updateAssigneeForTicketBatch = async (payload: TicketBatchAssignationPayload): Promise<void> => {
        await Api.post("/admn/ticketing_bulk_assign/", payload)
    }

    const ticketBatchPayload = async (action: string, payload: TicketBatchPayload | number[]): Promise<void> => {
        await Api.post(`/admn/ticketing_bulk_action/${action}/`, payload)
    }

    const pickupOldestTickets = async (): Promise<void> => {
        await Api.post("/admn/ticketing_auto_assign/")
    }

    const assignees = ref<TicketAssignee[]>([])
    const getAssignees = async (): Promise<TicketAssignee[]> => {
        assignees.value = await Api.get("/admn/admin_users_limited/?only_active=true&is_crm_user=true")
        return assignees.value
    }

    const openFileSession = async (ticketId: number): Promise<ChatMessage> => {
        const chatMessage: ChatMessage = await Api.get(`/admn/ticketing/${ticketId}/file_session/`)
        return chatMessage
    }

    const ticketGroupChanged = (ticketGroup: TicketGroup) => {
        const tableFilterConf: TicketingRouteConf | undefined = TICKETING_CONF_PER_GROUP.get(ticketGroup)
        if (!tableFilterConf) return

        ticketTablePageParams.value.page = 1
        ticketTableFilters.value = {
            ...ticketTableFilters.value,
            keyword: "",
            states: tableFilterConf.stateFilterDefaultValues || []
        }
    }
    watchDebounced(
        [ticketTablePageParams, ticketTableFilters],
        () => {
            ticketingEventHub.emit("refresh-ticketing-datas")
        },
        {
            deep: true,
            debounce: 200
        }
    )

    return {
        tickets,
        ticketTotalCount,
        ticketingStats,

        ticketGroupChanged,
        ticketTableFilters,
        ticketTablePageParams,
        getTickets,
        isTicketListLoading,
        getTicketingStats,

        getTicketDetailsWithoutMessages,
        getTicketMessages,
        getTicketsDetails,
        ticketsDetails,
        ticketIdsToLoad,
        detailedTicketToDisplay,

        getAssignees,
        assignees,

        updateTicketDetails,
        sendMessage,
        updateAssigneeForTicketBatch,
        ticketBatchPayload,
        pickupOldestTickets,
        openFileSession
    }
})
