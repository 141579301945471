const Gifts = () => import("@/views/crm/Gifts.vue")
const BatchGifts = () => import("@/views/crm/BatchGifts.vue")
export default [
    {
        path: "/admin/crm/gifts/",
        component: Gifts,
        name: "GiftsIndex",
        meta: {
            label: "Gifts table"
        }
    },
    {
        path: "/admin/crm/batch-gifts/",
        component: BatchGifts,
        name: "BatchGifts",
        meta: {
            label: "Gifts batch"
        }
    }
]
