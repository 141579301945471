import Api from "@/lib/Api"
import { FavoritePage, MenuGroupValue } from "@/models/menus/menu"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useMenuStore = defineStore("menu", () => {
    const isMenuExpanded = ref(true)
    const toggleMenuExpansion = () => {
        isMenuExpanded.value = !isMenuExpanded.value
        railModeChanged(!isMenuExpanded.value)
    }

    const openMenus = ref<MenuGroupValue[]>([])
    const openMenusBackup = ref<MenuGroupValue[]>([])
    const openFavoriteMenus = ref<string[]>([])
    const openFavoriteMenusBackup = ref<string[]>([])

    const railModeChanged = (isRailMode: boolean) => {
        isRailMode ? deactivateRailMode() : activateRailMode()
    }

    const deactivateRailMode = () => {
        openMenusBackup.value = [...openMenus.value]
        openMenus.value = []
        openFavoriteMenusBackup.value = [...openFavoriteMenus.value]
        openFavoriteMenus.value = []
    }

    const activateRailMode = () => {
        openMenus.value = [...openMenusBackup.value]
        openFavoriteMenus.value = [...openFavoriteMenusBackup.value]
    }

    const favorites = ref<FavoritePage[]>([])
    const getFavoritePages = async (): Promise<FavoritePage[]> => {
        try {
            const fetchedFavorites: FavoritePage[] = await Api.get("/admn/favorite_pages/")
            favorites.value = fetchedFavorites
            return fetchedFavorites
        } catch (err) {
            favorites.value = []
            throw err
        }
    }
    const addFavorite = async (payload: Omit<FavoritePage, "id">): Promise<FavoritePage> => {
        const createdFavorite: FavoritePage = await Api.post("/admn/favorite_pages/", payload)
        favorites.value.push(createdFavorite)
        return createdFavorite
    }
    const deleteFavorite = async (id: number): Promise<void> => {
        await Api.delete(`/admn/favorite_pages/${id}/`)
        favorites.value = favorites.value.filter(fav => fav.id !== id)
    }

    return {
        openMenus,
        openFavoriteMenus,
        isMenuExpanded,
        toggleMenuExpansion,
        railModeChanged,
        deactivateRailMode,
        activateRailMode,
        favorites,
        getFavoritePages,
        addFavorite,
        deleteFavorite
    }
})
