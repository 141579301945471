import { MenuGroup, MenuLink } from "@/models/menus/menu"
import { MenuType } from "../enums"

export const MENU_GROUPS: MenuGroup[] = [
    {
        value: "crm",
        title: "CRM",
        icon: "mdi-face-agent",
        links: [
            {
                title: "Tickets",
                to: { path: "/admin/ticketing/list/" },
                adminUserAuthorizations: ["auth_crm", "auth_crm_admin"],
                menuToDisplay: MenuType.TICKETING
            },
            {
                title: "Tickets batch process",
                href: "/admin/crm_batch",
                adminUserAuthorizations: ["auth_crm", "auth_crm_admin"]
            },
            {
                title: "Audrey prefilled answers",
                href: "/admin/prefilled_answer"
            },
            {
                title: "Transit pass validation",
                to: { path: "/admin/transit-pass-validation" },
                adminUserAuthorizations: ["auth_crm_sensitive"]
            },
            {
                title: "Driver license validation",
                to: { path: "/admin/driver-license-validation" },
                franceOnly: true,
                adminUserAuthorizations: ["auth_crm_sensitive"]
            },
            {
                title: "Gift",
                to: { path: "/admin/crm/gifts/" }
            },
            {
                title: "Batch gifts",
                to: { path: "/admin/crm/batch-gifts" }
            },
            {
                title: "User finder",
                to: { path: "/admin/user_finder" }
            },
            {
                title: "Batch notifs",
                to: { path: "/admin/batch-notifications" }
            },
            {
                title: "Batch messages",
                to: { path: "/admin/chat_batch" }
            },
            {
                title: "Batch custom messages",
                href: "/admin/chat_batch_custom"
            },
            {
                title: "Newsflash",
                to: { path: "/admin/flash_info/" }
            },
            {
                title: "Remote Configs",
                to: { path: "/admin/remote_config/" }
            },
            {
                title: "Challenges",
                to: { path: "/admin/challenges" }
            },
            {
                title: "Cee Audits",
                to: { path: "/admin/cee_audit/" },
                franceOnly: true,
                adminUserAuthorizations: ["auth_crm_sensitive"]
            },
            {
                title: "Suspicious check",
                to: { path: "/admin/suspicious-check/" },
                adminUserAuthorizations: ["auth_crm_sensitive"]
            }
        ]
    },
    {
        value: "other",
        title: "Other",
        icon: "mdi-shape-plus-outline",
        links: [
            {
                title: "User locations",
                to: { path: "/admin/user-locations" },
                adminUserAuthorizations: ["auth_crm_sensitive"]
            },
            {
                title: "User map",
                to: { path: "/admin/user_map" }
            },
            {
                title: "Faq",
                href: "/admin/faq",
                franceOnly: true,
                adminUserAuthorizations: ["auth_crm_sensitive"]
            },
            {
                title: "Prizes",
                to: { path: "/admin/prizes/" },
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Share configs",
                to: { path: "/admin/share_config" },
                adminUserAuthorizations: ["auth_crm"]
            },
            {
                title: "Promo codes",
                href: "/admin/promo_code_template",
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Mangopay events",
                href: "/admin/payment_logs",
                adminUserAuthorizations: ["auth_crm"]
            },
            {
                title: "Events",
                to: { path: "/admin/karos-event" },
                adminUserAuthorizations: ["auth_crm", "auth_sales", "auth_kam"]
            },
            {
                title: "Segments",
                to: { path: "/admin/segments" },
                adminUserAuthorizations: ["auth_kam"]
            },
            {
                title: "Spots",
                href: "/admin/spots"
            },
            {
                title: "Suspicious crews",
                href: "/admin/suspicious_crews",
                adminUserAuthorizations: ["auth_crm_sensitive"]
            },
            {
                title: "Cancel matches payment",
                to: { path: "/admin/cancel-matches-payment" },
                adminUserAuthorizations: ["auth_crm_sensitive"]
            },
            {
                title: "Batch ban users",
                to: { path: "/admin/batch-ban-users" },
                adminUserAuthorizations: ["auth_crm_sensitive"]
            },
            {
                title: "User Segment",
                to: { path: "/admin/user-segment" },
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Admin user",
                to: { path: "/admin/admin_user/" },
                adminUserAuthorizations: ["auth_superadmin"]
            }
            // {
            //     title: "App release & EULA",
            //     to: { path: "/admin/app_release_eula/" }
            // }
        ]
    },
    {
        value: "partners",
        title: "Partners",
        icon: "mdi-domain",
        links: [
            {
                title: "Partners",
                to: { path: "/admin/partners" },
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Partner parking",
                href: "/admin/partner_parking",
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Partner map",
                to: { path: "/admin/partner-map" }
            },
            {
                title: "Kpi deployment",
                to: { path: "/admin/kpi_deployment" },
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Transit pass",
                to: { path: "/admin/transit-pass" },
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Saas parameters",
                href: "/admin/saas_parameters",
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Add bulk partners",
                to: { path: "/admin/add-bulk-partners" },
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Transit Lines",
                to: { path: "/admin/transit-lines" }
            },
            {
                title: "Pricing rules",
                to: { path: "/admin/pricing_rules/" }
            },
            {
                title: "Mobility packages",
                to: { path: "/admin/mobility-package" },
                franceOnly: true
            },
            {
                to: { path: "/admin/partner-match-listing" },
                title: "Partner match listing"
            },
            {
                href: "/admin/partner_representatives",
                title: "Partner representatives"
            },
            {
                title: "Account managers profiles",
                to: { path: "/admin/account_managers/" },
                adminUserAuthorizations: ["auth_crm", "auth_kam"]
            },
            {
                title: "Geojson generator",
                to: { path: "/admin/geojson_generator/" }
            },
            {
                title: "Areas & Spots",
                to: { path: "/admin/carpool-place" }
            }
        ]
    },
    {
        value: "users",
        title: "Users",
        icon: "mdi-account-multiple",
        links: [
            {
                href: "/admin/user",
                title: "User",
                adminUserAuthorizations: ["auth_crm"]
            },
            {
                href: import.meta.env.VITE_RIDE_PAIR_TESTER_URL,
                title: "Ride pair tester",
                adminUserAuthorizations: ["auth_basis"]
            }
        ]
    }
]
MENU_GROUPS.forEach((menuGroup: MenuGroup) => {
    menuGroup.links.sort((link1: MenuLink, link2: MenuLink) => {
        return link1.title.localeCompare(link2.title)
    })
})
