import adminUser from "@/router/other/adminUser"
import appReleaseEula from "@/router/other/appReleaseEula"
import batchBanUsers from "@/router/other/batchBanUsers"
import cancelMatchesPayment from "@/router/other/cancelMatchesPayment"
import carpoolPlace from "@/router/other/carpoolPlace"
import karosEvent from "@/router/other/karosEvent"
import kpiDeployment from "@/router/other/kpiDeployment"
import prizes from "@/router/other/prizes"
import segment from "@/router/other/segment"
import shareConfig from "@/router/other/shareConfig"
import userMap from "@/router/other/user_map"
import userLocations from "@/router/other/userLocations"
import userSegment from "@/router/other/userSegment"
import { RouteRecordRaw } from "vue-router"

export const otherRoutes: RouteRecordRaw[] = [
    ...shareConfig,
    ...userMap,
    ...karosEvent,
    ...adminUser,
    ...carpoolPlace,
    ...kpiDeployment,
    ...appReleaseEula,
    ...segment,
    ...userSegment,
    ...userLocations,
    ...prizes,
    ...cancelMatchesPayment,
    ...batchBanUsers
]

export default otherRoutes
