const UserLocations = () => import("@/views/other/UserLocations.vue")

const routes = [
    {
        path: "/admin/user-locations",
        name: "userLocationsIndex",
        component: UserLocations,
        meta: {
            label: "User locations"
        }
    }
]

export default routes
