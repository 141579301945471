const PrizesIndex = () => import("@/views/other/prizes/PrizesIndex.vue")

const moduleBasePath = "/admin/prizes/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "PrizesIndex",
        component: PrizesIndex,
        meta: {
            label: "Prizes"
        }
    }
]

export default routes
