const CancelMatchesPayment = () => import("@/views/other/CancelMatchesPayment.vue")
export default [
    {
        path: "/admin/cancel-matches-payment",
        component: CancelMatchesPayment,
        name: "CancelMatchesPayment",
        meta: {
            label: "Cancel Matches Payment"
        }
    }
]
