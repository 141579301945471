const Segments = () => import("@/views/other/UserSegment.vue")

const routes = [
    {
        path: "/admin/user-segment/",
        name: "User segments",
        component: Segments,
        meta: {
            label: "User segments"
        }
    }
]

export default routes
