<template>
    <v-toolbar density="default" color="transparent" class="px-4" height="55">
        <div class="page-header-container display-under-body">
            <div class="page-header-wrapper">
                <div class="header__nav-wrapper">
                    <slot name="prepend" />
                    <router-link
                        :to="logoRedirection"
                        :class="[
                            'header__home-btn-container pa-4',
                            { 'header__home-btn-container--inactive': !logoRedirection }
                        ]"
                        data-testid="logo-link">
                        <img class="menu-logo" src="/assets/admin_logo.svg" alt="Admin logo" />
                    </router-link>
                    <v-menu>
                        <template #activator="{ props }">
                            <v-btn variant="text" color="default" v-bind="props" append-icon="mdi-triangle-small-down">
                                <p class="env-tag-wrapper">
                                    <span class="text-h6 font-weight-bold">Admin</span>
                                    <span class="text-h6" :class="[isProd ? 'text-primary' : 'text-warning']">
                                        {{ isProd ? "Prod" : "Preprod" }}
                                    </span>
                                </p>
                            </v-btn>
                        </template>
                        <v-list class="menu-links-list-wrapper" data-testid="env-option-list">
                            <v-list-item v-for="(item, index) in envList" :key="index" :value="index">
                                <v-list-item-title>
                                    <a :href="sanitizeUrl(item.url)" class="env-tag-wrapper">
                                        <span class="text-h6 font-weight-bold">Admin</span>
                                        <span
                                            :class="[
                                                'text-h6',
                                                item.label === 'Prod' ? 'text-primary' : 'text-warning'
                                            ]">
                                            {{ item.label }}
                                        </span>
                                    </a>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <slot name="append" />
            </div>
        </div>
    </v-toolbar>
</template>

<script lang="ts" setup>
import { sanitizeUrl } from "@braintree/sanitize-url"
import { computed } from "vue"

withDefaults(
    defineProps<{
        logoRedirection?: string
    }>(),
    {
        logoRedirection: ""
    }
)

const envList = [
    {
        url: "https://admin.karos.fr/admin/login",
        label: "Prod"
    },
    {
        url: "https://admin-preprod.karos.fr/admin/login",
        label: "Preprod"
    }
]

const isProd = computed(() => import.meta.env.MODE === "prod")
</script>

<style lang="scss" scoped>
@use "@/styles/index" as style;

.v-toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
}
.page-header-container {
    width: 100%;
}
.page-header-wrapper {
    position: relative;
    height: style.$header-height;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    .header__nav-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header__home-btn-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        &.header__home-btn-container--inactive {
            cursor: default;
        }
        .menu-logo {
            max-width: 40px;
            display: block;
            object-fit: contain;
        }
    }
}
.env-tag-wrapper {
    span {
        margin: 0 3px;
    }
}
.menu-links-list-wrapper {
    a,
    a:visited {
        text-decoration: none;
        color: inherit;
    }
}
</style>
