import { computed, ref } from "vue"
import { defineStore } from "pinia"
import { deleteCookie } from "@/lib/cookies"

import amplitudeWrapper from "@/lib/amplitudeWrapper"
import Api from "@/lib/Api"
import COUNTRY_CONFS from "@/constants/country"
import { AdminUser, CountryConf, CountryConfKey, Teamspace } from "@/models/core"

export const useAuthStore = defineStore("auth", () => {
    const adminUser = ref<AdminUser>()
    const teamspace = ref<Teamspace>()
    const isFrenchAdminUser = computed<boolean>(() => {
        return !adminUser.value?.country
    })
    const setCurrentTeamspace = async (teamspacePayload: Teamspace): Promise<AdminUser | undefined> => {
        if (!adminUser.value) Promise.resolve()
        await Api.post(`/admn/admin_user/${adminUser.value?.id}/set_current_teamspace/`, teamspacePayload)
        teamspace.value = teamspacePayload
        adminUser.value = {
            ...adminUser.value,
            ...teamspace.value
        }
        return adminUser.value
    }

    const getMyAdminUser = async (): Promise<AdminUser> => {
        try {
            const adminUserResponse: AdminUser = await Api.get("/admn/admin_users/me/")
            adminUser.value = adminUserResponse
            teamspace.value = {
                carpool_app: adminUserResponse.carpool_app,
                country: adminUserResponse.country
            }
            amplitudeWrapper.init(adminUser.value)
            return adminUserResponse
        } catch (err) {
            adminUser.value = undefined
            throw err
        }
    }

    const logout = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            localStorage.clear()
            deleteCookie("kadmin_login")
            deleteCookie(import.meta.env.VITE_AUTH_TOKEN_LABEL)
            deleteCookie(import.meta.env.VITE_AUTH_TOKEN_LABEL_TO_DEPRECATE)
            Api.get("/admn/disconnect/")
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    window.open("/admin/login", "_self")
                })
        })
    }

    const country = computed<CountryConfKey>(() => adminUser.value?.country || "fr")
    const countryConf = computed<CountryConf>(() => COUNTRY_CONFS[country.value])

    return {
        adminUser,
        teamspace,
        isFrenchAdminUser,
        setCurrentTeamspace,
        logout,
        getMyAdminUser,
        country,
        countryConf
    }
})
