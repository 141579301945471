<template>
    <div class="header__env-list-wrapper">
        <v-menu>
            <template #activator="{ props }">
                <v-btn variant="text" class="px-2" color="default" v-bind="props" data-testid="teamspace-button">
                    <img
                        class="mr-2"
                        data-testid="teamspace-logo"
                        :src="GET_TEAMSPACE_LOGO(authStore.adminUser?.country || null)"
                        alt="" />
                    <span class="text-subtitle-2 font-weight-medium" data-testid="teamspace-lang">
                        {{ authStore.adminUser?.country?.toUpperCase() || "FR" }}
                    </span>
                </v-btn>
            </template>
            <v-list class="menu-links-list-wrapper" data-testid="teamspace-list">
                <v-list-item
                    v-for="(item, index) in sortedAdminUserTeamspace"
                    :key="index"
                    :value="item.country"
                    class="py-3 px-4"
                    @click="setActiveTeamspace(item.country, item.carpool_app)">
                    <v-list-item-title class="d-flex">
                        <img
                            class="mr-4"
                            :src="GET_TEAMSPACE_LOGO(item.country)"
                            :alt="`${GET_COUNTRY_CONF(item.country).appDisplayName} logo`" />
                        <span class="text-b1">{{
                            `${GET_COUNTRY_CONF(item.country).appDisplayName} ${item.country?.toUpperCase() || "FR"}`
                        }}</span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { useRouter } from "vue-router"
import { GET_COUNTRY_CONF, GET_TEAMSPACE_LOGO } from "@/constants/teamspace"
import { Teamspace } from "@/models/core"
import { useAuthStore } from "@/store/core/auth"

const authStore = useAuthStore()
const sortedAdminUserTeamspace = computed(() => {
    return authStore.adminUser?.teamspaces?.sort((a: Teamspace, b: Teamspace) => {
        const appNameA = GET_COUNTRY_CONF(a.country).appDisplayName
        const appNameB = GET_COUNTRY_CONF(b.country).appDisplayName
        return appNameA.localeCompare(appNameB)
    })
})
const router = useRouter()

const setActiveTeamspace = (lang: string | null, carpoolAppIndex: number) => {
    if (lang == "fr") lang = null
    const teamspaceItem = {
        country: lang,
        carpool_app: carpoolAppIndex
    } as Teamspace

    authStore.setCurrentTeamspace(teamspaceItem).then(() => {
        router.push("/admin/")
    })
}
</script>
<style lang="scss">
.menu-links-list-wrapper {
    width: 198px;
}
</style>
