const BatchBanUsers = () => import("@/views/other/BatchBanUsers.vue")

const routes = [
    {
        path: "/admin/batch-ban-users",
        name: "BatchBanUsersIndex",
        component: BatchBanUsers,
        meta: {
            label: "Batch ban users"
        }
    }
]

export default routes
