import { RouteRecordRaw } from "vue-router"

const SuspiciousCheckIndex = () => import("@/views/crm/suspiciousCheck/SuspiciousCheckIndex.vue")

const moduleBasePath = "/admin/suspicious-check"

const routes: RouteRecordRaw[] = [
    {
        path: `${moduleBasePath}/:status`,
        name: "suspiciousCheckIndex",
        component: SuspiciousCheckIndex,
        meta: {
            label: "Suspicious Check"
        }
    },
    {
        path: `${moduleBasePath}`,
        redirect: { name: "suspiciousCheckIndex", params: { status: "indeterminate" } }
    }
]

export default routes
